



















import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "ExtraDetails",
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  methods: {
    transform_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", this.color);
      return icon;
    }
  }
});
