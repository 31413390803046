















































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AUTH_LOADING,
  GET_USER_DETAILS,
  GET_USER_NAME,
  UPDATE_USER_DETAILS
} from "@/store/modules/auth/constants";
import { EditProfile } from "@/interfaces/shared/view_profile/edit_profile";
import {
  clean_string,
  get_facebook_regex,
  get_instagram_regex,
  get_linkedin_regex,
  get_twitter_regex,
  validate_file_type
} from "@/utils/global";
import { SocialLinkInit } from "@/interfaces/onboarding/onboarding";
import { SocialLinks } from "@/store/modules/onboarding/interfaces";
import { ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { scoreUtils } from "@/utils";

export default Vue.extend({
  name: "EditProfile",
  data(): EditProfile {
    return {
      edit_profile_form: false,
      social_channels: [],
      required_rule: [(value: string) => !!value || "Field is required"],
      availability_rules: [
        (value: string[]) =>
          value.length > 0 || this.$t("candidate-onboarding.availability-error")
      ],
      profile_form: {
        name: "",
        first_name: "",
        last_name: "",
        availability: [],
        social_links: [],
        avatar: null
      },
      error_msg: "",
      show_error_alert: false,
      selected_avatar: null,
      items: [
        {
          text: this.$t("candidate-onboarding.availability-op1"),
          value: "online"
        },
        {
          text: this.$t("candidate-onboarding.availability-op2"),
          value: "on site"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("auth", {
      auth_loading: AUTH_LOADING,
      user_details: GET_USER_DETAILS,
      get_user_name: GET_USER_NAME
    })
  },
  mounted() {
    if (this.user_details) {
      this.profile_form.first_name = this.user_details.first_name;
      this.profile_form.last_name = this.user_details.last_name
        ? this.user_details.last_name
        : "";
      this.profile_form.name =
        this.profile_form.first_name + " " + this.profile_form.last_name;

      this.profile_form.availability = this.user_details.availability;
      this.profile_form.social_links = this.user_details.social_links;
      this.social_channels = [
        {
          name: "Facebook",
          v_model: this.get_social_link_by_name("facebook"),
          prepend_icon: require("@/assets/logos/facebook.svg"),
          field_rules: [
            (value: string) => {
              if (value) {
                if (get_facebook_regex().test(value)) return true;
                else return this.$t("candidate-onboarding.invalid-fb-url");
              } else return true;
            }
          ]
        },
        {
          name: "Twitter",
          v_model: this.get_social_link_by_name("twitter"),
          prepend_icon: require("@/assets/logos/twitter.svg"),
          field_rules: [
            (value: string) => {
              if (value) {
                if (get_twitter_regex().test(value)) return true;
                else return this.$t("candidate-onboarding.invalid-twitter-url");
              } else return true;
            }
          ]
        },
        {
          name: "Linkedin",
          v_model: this.get_social_link_by_name("linkedin"),
          prepend_icon: require("@/assets/logos/linkedin-3.svg"),
          field_rules: [
            (value: string) => {
              if (value) {
                if (get_linkedin_regex().test(value)) return true;
                else
                  return this.$t("candidate-onboarding.invalid-linkedin-url");
              } else return true;
            }
          ]
        },
        {
          name: "Instagram",
          v_model: this.get_social_link_by_name("instagram"),
          prepend_icon: require("@/assets/logos/instagram.svg"),
          field_rules: [
            (value: string) => {
              if (value) {
                if (get_instagram_regex().test(value)) return true;
                else return this.$t("candidate-onboarding.invalid-insta-url");
              } else return true;
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("auth", {
      update_user_details: UPDATE_USER_DETAILS
    }),
    /**
     * Function to cancel uploading profile picture
     */
    cancel_action() {
      this.edit_profile_form = false;
      this.social_channels = [];
      this.profile_form = {
        name: "",
        first_name: "",
        last_name: "",
        availability: [],
        social_links: [],
        avatar: null
      };
      this.error_msg = "";
      this.show_error_alert = false;
      this.selected_avatar = null;
      this.$emit("cancel", false);
    },
    /**
     * Function to open file uploader to upload profile picture
     */
    open_file_uploader() {
      const file_uploader = this.$refs.profile_picture_uploader as any;
      file_uploader.$refs.input.click();
    },
    /**
     * Validate user selected avatar through file uploader
     * @param file{File}
     */
    process_selected_avatar(file?: File) {
      if (!file) {
        this.profile_form.avatar = null;
        this.selected_avatar = null;
        this.error_msg = "";
        this.show_error_alert = false;
        return;
      }
      this.profile_form.avatar = file;
      if (this.validate_avatar_type(file)) {
        this.get_avatar_url(file);
      }
    },
    /**
     * Validate selected avatar type: .png, .jpg & .svg are valid types
     * @param file{File}  => selected file
     * @return boolean
     */
    validate_avatar_type(file: File): boolean {
      if (file.size > 2e6) {
        this.error_msg = this.$t("candidate-onboarding.size-error");
        this.show_error_alert = true;
        this.profile_form.avatar = null;

        this.selected_avatar = null;
        return false;
      } else if (validate_file_type("img", file)) {
        this.error_msg = "";
        this.show_error_alert = false;
        return true;
      } else {
        this.error_msg = this.$t(
          "candidate-onboarding.candidate-avatar-description"
        );
        this.show_error_alert = true;
        this.profile_form.avatar = null;

        this.selected_avatar = null;
        return false;
      }
    },
    /**
     * Get avatar url to display
     * @param avatar{File}  => selected file
     */
    get_avatar_url(avatar: File) {
      const reader = new FileReader();
      reader.addEventListener("load", (e: ProgressEvent) => {
        const target = e.target as FileReader;
        if (target) this.selected_avatar = target.result;
        else {
          this.error_msg = this.$t(
            "candidate-onboarding.candidate-avatar-description"
          );
          this.profile_form.avatar = null;
          this.selected_avatar = null;
        }
      });
      reader.readAsDataURL(avatar);
    },
    /**
     * Get social media url by name
     * @param name => name of the platform
     * @return {string|null}
     */
    get_social_link_by_name(name: string): string | null {
      if (
        this.user_details &&
        this.user_details.social_links &&
        this.user_details.social_links.length > 0
      ) {
        const exist: SocialLinks = this.user_details.social_links.find(
          (link: SocialLinks) =>
            clean_string(link.platform) === clean_string(name)
        );
        if (exist) return exist.url;
        return null;
      } else return null;
    },
    /**
     * Submit edit profile form
     */
    async submit_form() {
      const form = new FormData();
      // If user updated username
      if (this.profile_form.name !== this.get_user_name) {
        // Split full name & extracting first & last name
        const split_name = this.profile_form.name.split(" ");
        const first_name = split_name[0];
        let last_name: string | null = null;
        if (split_name.length > 1) {
          last_name = split_name.slice(1).join(" ");
          form.append("last_name", last_name as never);
        } else form.append("last_name", "");
        form.append("first_name", first_name);
      }

      form.append(
        "availability",
        JSON.stringify(this.profile_form.availability)
      );
      const social_links: SocialLinks[] = [];
      this.social_channels.forEach((value: SocialLinkInit) => {
        const obj: SocialLinks = {
          platform: clean_string(value.name),
          url: value.v_model ?? ""
        };
        social_links.push(obj);
      });
      form.append("social_links", JSON.stringify(social_links));
      if (this.profile_form.avatar) {
        form.append("avatar", this.profile_form.avatar as never);
      }

      const result = await this.update_user_details(form);
      if (result) {
        this.root_notification("Profile Updated Successfully");
        this.cancel_action();
      }
      scoreUtils.clearProfileScore();
    }
  }
});
